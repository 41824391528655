import React from 'react';

export function useWidth() {
  const widthRef = React.useRef<null | HTMLDivElement>(null);
  const [width, setWidth] = React.useState(0);

  React.useLayoutEffect(() => {
    const w = widthRef.current?.getBoundingClientRect().width;
    setWidth(w || 0);
  }, []);

  React.useEffect(() => {
    function handleResize() {
      const w = widthRef.current?.getBoundingClientRect().width;
      setWidth(w || 0);
    }

    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { widthRef, width };
}
