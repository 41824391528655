import React from 'react';
import { lightTheme, MODULE_THEMES, Theme } from '../Theme';
import {
  ButtonPrimary,
  createBasicColorSet,
} from '@spotify-internal/encore-web';
import { fixHref } from '../utils';
import { useRouter } from 'next/router';
import { useTracking } from '../Tracking';

export type CTAButtonType = {
  ctaText: string;
  ctaLink: string;
  title?: string;
  themeName?: string;
  trackingCategory?: string;
  backgroundColor?: string;
  textColor?: string;
};

export const CTAButton: React.FC<CTAButtonType> = ({
  ctaText = '',
  ctaLink = '',
  title = '',
  themeName = '',
  trackingCategory = '',
  backgroundColor = '',
  textColor = '',
}) => {
  const router = useRouter();
  const { eventHandlerWithAction } = useTracking();

  const theme: Theme = {
    ...lightTheme,
    ...(MODULE_THEMES[themeName] || {}),
  };
  // Color set cannot be created with css variables, so we extract the hex value from the color string
  const ctaTextColor =
    theme.cta.text.match(/#[a-f0-9]+/i)?.at(0) || theme.box.background;
  const buttonColorSet = createBasicColorSet(
    backgroundColor || theme.cta.background,
    textColor || ctaTextColor,
  );
  const formattedHref = fixHref(ctaLink);
  const useInternalNavigation = formattedHref?.startsWith('/');

  const onLinkClick = () => {
    eventHandlerWithAction(
      `Frodor - ${trackingCategory}`,
      'click',
      `CTA Link - ${title}`,
    )();
    if (useInternalNavigation) {
      router.push(formattedHref as string);
    }
  };

  return (
    <ButtonPrimary
      href={useInternalNavigation ? undefined : formattedHref}
      onClick={() => onLinkClick()}
      UNSAFE_colorSet={buttonColorSet}
    >
      {ctaText}
    </ButtonPrimary>
  );
};
