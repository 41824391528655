// ignore-string-externalization

import React from 'react';
import {
  ButtonSecondary,
  ButtonPrimary,
  ButtonTertiary,
  TabItem,
} from '@spotify-internal/encore-web';
import { Link } from './Link';
import styled from 'styled-components';

type EncoreComponentProps<
  EncoreComponent extends React.ElementType,
  Component extends React.ElementType,
> = Omit<React.ComponentProps<EncoreComponent>, 'component'> &
  React.ComponentProps<Component>;

const StyledButtonPrimary = styled(ButtonPrimary)`
  &::after {
    border: 0;
  }
`;

export function ButtonPrimaryAsLink(
  props: EncoreComponentProps<typeof ButtonPrimary, typeof Link>,
) {
  return <StyledButtonPrimary {...props} component={Link} />;
}

export function ButtonSecondaryAsLink(
  props: EncoreComponentProps<typeof ButtonSecondary, typeof Link>,
) {
  return <ButtonSecondary {...props} component={Link} />;
}

export function ButtonTertiaryAsLink(
  props: EncoreComponentProps<typeof ButtonTertiary, typeof Link>,
) {
  return <ButtonTertiary buttonSize="sm" {...props} component={Link} />;
}

export function NavBarListItemAsLink(
  props: EncoreComponentProps<typeof TabItem, typeof Link>,
) {
  return <TabItem {...props} component={Link} />;
}
