// ignore-string-externalization

import React from 'react';
import styled from 'styled-components';
import { cssSpacing } from '@spotify-internal/encore-web';
import { failure, success } from '../../../features/src/encore-legacy-tokens';
import { ContentfulAsset } from '.';
import { EMPTY_IMAGE } from './constants';

export type AssetConstraints = {
  aspectRatio?: [number, number];
  contentTypes?: string[];
  excludeContentTypes?: string[];
  maxFileSize?: number;
  minDimensions?: [number, number];
  video?: {
    required?: boolean;
  };
  description?: {
    required?: boolean;
  };
};

const Overlay = styled.div<{ hasErrors: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(
    255,
    255,
    255,
    ${props => (props.hasErrors ? 0.8 : 0)}
  );
  color: ${failure};
  padding: ${cssSpacing('tighter')};
  border: 3px dashed ${props => (props.hasErrors ? failure : success)};
  text-align: left;
  overflow: auto;

  ul {
    li {
      list-style: disc;
      margin-bottom: ${cssSpacing('tighter')};
    }
  }
`;

export const AssetDebugFrame: React.FC<{
  pictureRef: React.RefObject<HTMLPictureElement>;
  asset: ContentfulAsset;
  constraints: AssetConstraints;
  children: React.ReactNode;
}> = ({ pictureRef, asset, constraints, children }) => {
  const errors = useConstraintChecks(pictureRef, asset, constraints);

  if (errors?.length) {
    // eslint-disable-next-line no-console
    console.warn(
      'Constraints on asset:',
      asset.title,
      ['', ...errors].join('\n --> '),
      asset,
    );
  }

  const hasErrors = Boolean(errors?.length);
  return (
    <>
      {children}
      <Overlay hasErrors={hasErrors}>
        <ul>
          {errors.map(err => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      </Overlay>
    </>
  );
};

export function useConstraintChecks(
  pictureRef: React.RefObject<HTMLPictureElement>,
  asset: ContentfulAsset,
  constraints: AssetConstraints,
): string[] {
  const [errors_, setErrors] = React.useState<string[]>([]);

  React.useEffect(() => {
    const errors: string[] = [];
    const currentSrc = getCurrentSrc(pictureRef);
    const isPlaceholder =
      (currentSrc || '').indexOf('h=10&w=12') > -1 ||
      currentSrc === EMPTY_IMAGE;

    const assetWidth = asset.file.details.image.width;
    const assetHeight = asset.file.details.image.height;
    let aw: number = 0;
    let ah: number = 0;

    if (assetWidth && assetHeight && constraints.aspectRatio) {
      [aw, ah] = constraints.aspectRatio;
    } else {
      const aspectBox = pictureRef.current?.closest('[data-aspect-ratio]');
      if (aspectBox) {
        [aw, ah] = aspectBox
          .getAttribute('data-aspect-ratio')!
          .split('x')
          .map(s => parseInt(s, 10));
      }
    }

    if (
      assetWidth &&
      assetHeight &&
      aw &&
      ah &&
      (aw / ah).toFixed(1) !== (assetWidth / assetHeight).toFixed(1)
    ) {
      const ar = gcd(assetWidth, assetHeight);
      errors.push(
        `Incorrect aspect ratio, should be: ${aw}x${ah} ` +
          `(is: ~${assetWidth / ar}x${assetHeight / ar}, ` +
          `full size: ${assetWidth}x${assetHeight})`,
      );
    }

    let defaultTypes = ['image/png', 'image/jpeg'];
    if (constraints.video) {
      if (constraints.video.required) defaultTypes = ['video/mp4'];
      else defaultTypes.push('video/mp4');
    }
    const ctype = asset?.file?.contentType;
    const ctypes = constraints.contentTypes || defaultTypes;
    if (ctype && ctypes && !ctypes.find(c => c === ctype)) {
      errors.push(
        `Invalid content type ${ctype} - should be one of: ${ctypes.join(
          ', ',
        )}`,
      );
    }

    const xctypes = constraints.excludeContentTypes;
    if (ctype && xctypes && xctypes.find(c => c === ctype)) {
      errors.push(
        `Invalid content type ${ctype} - should NOT be one of: ${xctypes.join(
          ', ',
        )}`,
      );
    }

    const minDim = constraints.minDimensions;
    if (
      minDim &&
      assetWidth &&
      assetHeight &&
      (assetWidth < minDim[0] || assetHeight < minDim[1])
    ) {
      errors.push(
        `Image too small ` +
          `(is: ${assetWidth}x${assetHeight}, ` +
          `should be at least: ${minDim[0]}x${minDim[1]})`,
      );
    }

    const maxSize = constraints.maxFileSize || 2000000;
    const fsize = asset.file.details.size;
    if (maxSize && fsize && fsize > maxSize) {
      errors.push(
        `File size is too large @ ${bytesToSize(fsize)} ` +
          `- should be less than: ${bytesToSize(maxSize)})`,
      );
    }

    if (
      !isSafari() &&
      currentSrc &&
      currentSrc.indexOf('fm=webp') === -1 &&
      !isPlaceholder
    ) {
      errors.push(
        `Non-safari browsers should display WebP image - URL is: ${currentSrc}`,
      );
    }

    if (constraints.description?.required !== false && !asset.description) {
      errors.push(`Missing description for alt text`);
    }

    setErrors(errors);
  }, []);

  return errors_;
}

function gcd(a: number, b: number): number {
  return b === 0 ? a : gcd(b, a % b);
}

function bytesToSize(bytes: number) {
  const byteSize = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = Math.floor(Math.log(bytes) / Math.log(byteSize));
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / byteSize ** i).toFixed(1)} ${sizes[i]}`;
}

function getCurrentSrc(pictureRef: React.RefObject<HTMLPictureElement>) {
  return pictureRef.current?.querySelector('img')?.currentSrc;
}

function isSafari() {
  return (
    typeof navigator !== 'undefined' &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  );
}
