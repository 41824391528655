// ignore-string-externalization

import React from 'react';
import styled from 'styled-components';
import { Supertext } from './Supertext';
import { Headline } from './Headline';

const HeadlineWithSupertext = styled.div<{
  text?: string;
  background?: string;
}>`
  background-color: ${({ background }) =>
    background ? background : 'inherit'};
  color: ${({ text, theme }) => (text ? text : theme.box.text)};
`;

type HeadlineWithSupertextComponentType = {
  supertext: string;
  headline?: string;
  text?: string;
  background?: string;
  centered?: boolean;
};

export const HeadlineWithSupertextComponent: React.FC<
  HeadlineWithSupertextComponentType
> = ({
  supertext = '',
  headline = '',
  text = '',
  background = '',
  centered = false,
}) => {
  return (
    <HeadlineWithSupertext text={text} background={background}>
      <Supertext centered={centered}>{supertext}</Supertext>
      {headline && <Headline centered={centered}>{headline}</Headline>}
    </HeadlineWithSupertext>
  );
};
