// ignore-string-externalization

import styled from 'styled-components';
import { Text } from '@spotify-internal/encore-web';
import {
  AnimationContextType,
  DELAYS,
  fadeInAnimation,
  withAnimationContext,
} from '@spotify-internal/frodor-modules';

export const StyledSupertext = styled(Text).attrs({
  forwardedAs: 'h6',
  variant: 'bodyMedium',
})<{ centered?: boolean; animationContext: AnimationContextType }>`
  ${fadeInAnimation(DELAYS.FIRST)};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  text-transform: uppercase;
  padding-bottom: 0px;
`;

export const Supertext = withAnimationContext(StyledSupertext);
