// ignore-string-externalization

import React from 'react';
import qs from 'query-string';
import NextLink from 'next/link';
import { hexToRgba } from '../../../features/src/color-conversion';
import styled, { css } from 'styled-components';
import { white } from '@spotify-internal/encore-web';
import { useTracking } from '../Tracking';
import { useSearchParams } from 'next/navigation';

type LinkProps = {
  children: React.ReactNode;
  track: [string, string, string?]; // category/label for GA tracking - category ignored for external
  href: string;
  prefetch?: boolean; // same as NextJS link
  external?: boolean; // external link opens new window, etc.
  component?: any; // underlying <a> component, mostly for use with styled-components
  highlight?: boolean;
  shallow?: boolean; // same as NextJS link
  tabIndex?: number;
  forceLoad?: boolean;
};

type Props = { [key: string]: any };

const defaultComponent = styled.a``;

/**
 * Wraps NextJS link, adds:
 * - track: GA tracking (required, auto for external)
 * - external: opens external links in new tab, etc.
 * - adds 'a' component with default link styles, configurable
 */
export const UnstyledLink: React.FC<LinkProps> = (props: LinkProps) => {
  const {
    children,
    track,
    href,
    external = false,
    prefetch = true,
    component: AComponent = defaultComponent,
    forceLoad = false,
    ...extraProps
  } = props;

  const { eventHandler, eventHandlerWithAction } = useTracking();

  const searchParams = useSearchParams();
  const linkProps: Props = extraProps;
  const aProps: Props = {};

  let url = href;
  // always try to pass the 'plain' parameter to the next URL
  if ('plain' in (searchParams || {})) {
    const { url: u, query: q } = qs.parseUrl(url);
    q.plain = null;
    url = `${u}?${qs.stringify(q)}`;
  }

  if (track) {
    let [trackCategory, trackLabel, trackAction] = track;
    if (external) {
      trackCategory = trackCategory || 'External Link';
      trackLabel = trackLabel || url;
      trackAction = trackAction || 'click';
    }
    if (trackCategory && trackLabel && trackAction) {
      aProps.onClick = eventHandlerWithAction(
        `Frodor - ${trackCategory}`,
        trackAction,
        trackLabel,
      );
    } else if (trackCategory && trackLabel) {
      aProps.onClick = eventHandler(`Frodor - ${trackCategory}`, trackLabel);
    }
  }

  if (external) {
    aProps.target = '_blank';
    aProps.rel = 'noopener noreferrer';
  } else if (forceLoad) {
    aProps.rel = 'noopener noreferrer';
  }

  if (linkProps.highlight) {
    aProps.className = 'highlight';
  }
  delete linkProps.highlight;

  aProps.tabIndex = linkProps.tabIndex;
  delete linkProps.tabIndex;

  if (
    prefetch === false ||
    external ||
    forceLoad ||
    (url &&
      (url.startsWith('http') ||
        url.startsWith('//') ||
        url === '/c' ||
        url.startsWith('/c/')))
  ) {
    linkProps.prefetch = false;
  }

  aProps.className = `${aProps.className || ''} ${linkProps.className || ''}`;
  delete linkProps.className;

  // pass along all data & aria props to href
  for (const key of Object.keys(linkProps).filter(
    k => k.startsWith('aria-') || k.startsWith('data-'),
  )) {
    aProps[key] = linkProps[key];
    delete linkProps[key];
  }

  const isHelpSite = url?.startsWith('/help/article');

  if (external || forceLoad || isHelpSite) {
    return (
      <AComponent {...aProps} href={url}>
        {children}
      </AComponent>
    );
  }

  return (
    <NextLink {...linkProps} href={url} passHref legacyBehavior>
      <AComponent {...aProps}>{children}</AComponent>
    </NextLink>
  );
};

export const getLinkStyle = (props: any) => css`
  text-decoration: none;
  color: ${hexToRgba(
    props.customTextColor || props.theme.link?.color || white,
    0.75,
  )};

  &:hover,
  &:focus:not(:disabled),
  &:active:not(:disabled),
  &.highlight {
    text-decoration: none;
    color: ${props.customTextColor || props.theme.link?.color || white};
    -webkit-tap-highlight-color: ${props.theme.link?.tap || 'transparent'};
  }
`;

export const Link = styled(UnstyledLink)<LinkProps>`
  ${props => getLinkStyle(props)}
`;
