// ignore-string-externalization

import React from 'react';
import styled from 'styled-components';
import { screenSmMax, cssSpacing } from '@spotify-internal/encore-web';
import { ContentfulImage } from '../Contentful/ContentfulImage';
import { ContentfulAsset } from '../Contentful';
import { HeadlineWithSupertextComponent } from './HeadlineWithSupertext';
import { ImageAspectBox21x9 } from './Box';

const FeaturedContent = styled.div`
  color: ${({ theme }) => theme.box.text};
  background-color: 'inherit';
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props: { padding: string }) => props.padding};

  @media (max-width: ${screenSmMax}) {
    padding-top: 0px;
    padding-bottom: ${cssSpacing('base')};
    flex-direction: column-reverse;
  }
`;

const ImageContainer = styled(ImageAspectBox21x9)``;

const InnerText = styled.div`
  max-width: 815px;
  padding-top: ${cssSpacing('looser')};

  @media (max-width: ${screenSmMax}) {
    padding-top: 0;
  }
`;

type FeaturedContentComponentType = {
  supertext?: string;
  headline?: string;
  image?: ContentfulAsset;
  useFeaturedContentHeadline: boolean;
  caption?: string;
};

export const FeaturedContentHeadline: React.FC<
  FeaturedContentComponentType
> = ({
  supertext = '',
  headline = '',
  image,
  useFeaturedContentHeadline,
  caption = '',
}) => {
  const padding = image ? `${cssSpacing('looser')}` : '0px';
  return (
    <FeaturedContent>
      <HeaderContent padding={padding}>
        {image && (
          <ImageContainer>
            <ContentfulImage asset={image} caption={caption} />
          </ImageContainer>
        )}

        {useFeaturedContentHeadline && headline && (
          <InnerText>
            <HeadlineWithSupertextComponent
              supertext={supertext}
              headline={headline}
            />
          </InnerText>
        )}
      </HeaderContent>
    </FeaturedContent>
  );
};
