// ignore-string-externalization

/* stylelint-disable value-keyword-case */
import styled, { css } from 'styled-components';
import {
  screenXsMax,
  screenSmMin,
  cssSpacing,
} from '@spotify-internal/encore-web';

type BoxProps = {
  noPadding?: boolean;
  flex?: string;
  transparent?: boolean;
};

export const Box = styled.div<BoxProps>`
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
  ${({ noPadding }) =>
    !noPadding &&
    css`
      @media (max-width: ${screenXsMax}) {
        padding: ${cssSpacing('looser')} ${cssSpacing('base')};
      }

      @media (min-width: ${screenSmMin}) {
        padding: ${cssSpacing('looser')} ${cssSpacing('looser-4')};
      }
    `}
  ${({ theme }) => css`
    background: ${theme.box.background};
    color: ${theme.box.text};
  `}
    ${({ transparent }) =>
    transparent &&
    css`
      background: transparent;
    `};
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ImageAspectBox = styled.div<{ ratio: number; fit?: string }>`
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-bottom: ${props => props?.ratio * 100}%;
  }

  video,
  img,
  iframe,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: ${props => props.fit || 'cover'};
  }
`;

export const ImageAspectBox1x1 = styled(ImageAspectBox).attrs({
  ratio: 1,
  'data-aspect-ratio': '1x1',
})``;
export const ImageAspectBox16x9 = styled(ImageAspectBox).attrs({
  ratio: 9 / 16,
  'data-aspect-ratio': '16x9',
})``;
export const ImageAspectBox21x9 = styled(ImageAspectBox).attrs({
  ratio: 9 / 21,
  'data-aspect-ratio': '21x9',
})``;
export const ImageAspectBox5x4 = styled(ImageAspectBox).attrs({
  ratio: 4 / 5,
  'data-aspect-ratio': '5x4',
})``;
export const ImageAspectBox3x5 = styled(ImageAspectBox).attrs({
  ratio: 5 / 3,
  'data-aspect-ratio': '3x5',
})``;
export const ImageAspectBox1x2 = styled(ImageAspectBox).attrs({
  ratio: 2 / 1,
  'data-aspect-ratio': '1x2',
})``;
